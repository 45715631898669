.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

label.MuiFormLabel-root.Mui-focused,
.MuiFormControl-root .MuiFormLabel-root.Mui-focused,
label.MuiFormLabel-root.MuiFormLabel-filled,
.textAreaField .MuiFormControl-root .MuiFormLabel-root {
  color: #805537;
}

.MuiInput-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.FormStyle .MuiInput-underline:after,
.FormStyle .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: #805537;
}

.GridStyle .MuiGrid-item {
  padding: 0 15px;
}

.GridStyle .MuiFormControl-root {
  width: 100%;
}
.GridMargin .MuiGrid-item {
  margin-bottom: 20px;
}
.innerGridHalf .MuiGrid-item {
  padding: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0 !important;
}
.innerGridHalf .MuiGrid-grid-sm-6 {
  max-width: 46%;
  flex-basis: 46%;
}
.MuiCheckbox-root {
  color: #989898 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked,
.MuiRadio-colorPrimary.Mui-checked {
  color: #2f72f2 !important;
}
.MuiCheckbox-colorPrimary .MuiSvgIcon-root {
  font-size: 1.4rem;
}

.MuiFormControl-root .MuiInputBase-root,
.MuiFormControlLabel-root .MuiTypography-root,
.MuiFormControl-root .MuiFormLabel-root {
  font-size: 0.925rem !important;
}

.alineaTable {
  box-shadow: none !important;
  background-color: transparent !important;
  width: 100%;
  max-width: 100%;
  margin-bottom: 6px;
}

.alineaTable .MuiTable-root {
  border: 0;
}

.alineaTable .MuiTable-root .MuiTableHead-root {
  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: #dfdfdf;
}
.cardTable .MuiTable-root .MuiTableHead-root {
  border-right: 0;
  border-color: #f4eddc !important;
}

.alineaTable .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
  color: #805537;
  text-transform: uppercase;
  min-height: 36px;
  height: 36px;
  padding-top: 3px;
  padding-bottom: 3px;
  position: relative;
  font-size: 0.7rem;
  font-weight: 500;
  background: #feffed;
}

.alineaTable .MuiTable-root .MuiTableHead-root .MuiTableCell-root div {
  display: inline-flex;
  align-items: center;
}

.alineaTable .MuiTable-root .MuiTableHead-root .MuiTableCell-root div img {
  margin-left: 10px;
}

.alineaTable .MuiTable-root .MuiTableCell-root a {
  color: rgb(72, 133, 254);
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.alineaTable .MuiTable-root tbody .MuiTableCell-root {
  background-color: #ffffff;
  white-space: nowrap;
}

.alineaTable .MuiTableCell-root {
  border-bottom: 3px solid #f4eddc;
  height: 49px;
  line-height: 1.1;
  padding: 3px 20px 3px 20px;
}
.alignTableDefault table tr th:last-child,
.alignTableDefault table tr td:last-child {
  text-align: left;
}
.alineaTable .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: 0 !important;
}

.alineaTable tbody.MuiTableBody-root {
  background-color: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #f4eddc;
  border-radius: 3px;
}
.alineaTable tbody tr td:last-child {
  right: 1px;
  position: relative;
}
.landingTable .MuiTable-root tbody .MuiTableCell-root {
  font-size: 0.8rem !important;
}
/* .removeAllData tbody tr td:last-child,
.showAllData tbody tr td:last-child,
.cardTable tbody tr td:last-child {
  right: auto;
} */
.tinyTableHeaderBg .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
  background: #fbfbfb !important;
}
.tinyTableHeaderBg .MuiTable-root .MuiTableHead-root {
  border: 1px solid #f4eddc;
}
.alineaTable .MuiTable-root .MuiTableHead-root .MuiTableCell-root,
.alineaTable .MuiTable-root tbody .MuiTableCell-root {
  max-width: 180px;
  /* text-overflow: ellipsis; */
  overflow: hidden;
}
.alineaTable .MuiTable-root tbody .MuiTableCell-root span.maxCellWidth {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.fui-wrapper .MuiTable-root .MuiTableHead-root {
  border-top-width: 1px !important;
}

.fui-wrapper table tbody tr td:first-child::before {
  content: '';
  border-left: 1px solid #f4eddc !important;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

/* .fui-wrapper table tbody tr td:last-child::before {
  content: "";
  border-right: 1px solid #f4eddc !important;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
} */

.cardTable .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
  background: none;
}

.cardTable tbody.MuiTableBody-root {
  box-shadow: none;
}

.maxDialog .MuiDialog-paper {
  max-width: 80% !important;
}
.maxDialog-2 .MuiDialog-paper {
  max-width: 90%;
}
.titleDialog .MuiDialogTitle-root {
  background: rgb(85, 114, 29);
  background: linear-gradient(
    0deg,
    rgba(85, 114, 29, 1) 0%,
    rgba(95, 138, 26, 1) 100%
  );
  padding: 0;
  overflow: hidden;
  position: relative;
}

.titleDialog .MuiDialogTitle-root,
.titleDialog .MuiDialogTitle-root .popupBgPic {
  min-height: 48px;
}

.titleDialog .popupBgPic {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: url(../src/assets/popup-header-bg.svg);
  background-size: 100%;
}

.titleDialog .popupBgPic h6 {
  font-weight: 300;
  font-size: 1rem;
  color: #fff;
  padding: 0 20px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif, Arial;
}

.titleDialog .closeButton {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #fff;
  padding: 5px;
}

.titleDialog .closeButton .MuiSvgIcon-root {
  font-size: 1.4rem;
}

.min-wd500 {
  min-width: 500px;
}
.dialogInnerContent {
  padding: 20px 0;
}
button.fab_btn {
  border-radius: 30px;
  font-size: 0.875rem;
  font-weight: 400;
  height: 36px;
  padding: 7px 15px 6px;
  color: #717171;
}
button.fab_btn:hover {
  background-color: #f4f4f4;
}
.fab_btn.MuiButton-containedPrimary {
  background-color: #2f72f2 !important;
  color: #ffffff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24) !important;
}
.fab_btn.Mui-disabled {
  color: #ffffff !important;
  opacity: 0.5;
}
.fab_btn.Mui-disabled .MuiButton-label {
  opacity: 0.9;
}

.fab_btn.MuiButton-containedSecondary {
  background-color: #ed1848 !important;
  color: #ffffff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24) !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiInputBase-root svg.MuiSelect-icon,
.MuiAutocomplete-popupIndicator svg.MuiSvgIcon-root {
  background: url(assets/module_drop_down.svg) no-repeat right;
  opacity: 0.54;
  width: 11px;
}

.MuiInputBase-root svg.MuiSelect-icon path,
.MuiAutocomplete-popupIndicator svg.MuiSvgIcon-root path {
  display: none;
}
.MuiAutocomplete-popupIndicator {
  margin-left: 7px !important;
}
.MuiAutocomplete-popupIndicator:hover {
  background-color: transparent !important;
}
.MuiAutocomplete-popupIndicator .MuiTouchRipple-root {
  display: none;
}
.track-horizontal {
  min-width: 100%;
  z-index: 400;
  bottom: 0;
  left: 0;
  border-radius: 10px;
}

.track-horizontal > div {
  border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.track-vertical {
  z-index: 400;
  border-radius: 10px;
  position: absolute;
  left: auto;
  right: 0;
  bottom: 2px;
  top: 2px;
}
.track-vertical > div {
  border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.track-v-r8 {
  right: 8px !important;
}
.MuiAutocomplete-popup {
  z-index: 9999 !important;
}
.MuiAutocomplete-popup option {
  text-transform: uppercase;
}
.MuiAutocompleteCustom .MuiChip-root {
  background-color: #f0f0f0;
  text-transform: uppercase;
}
.MuiAutocompleteCustom .MuiChip-root .MuiChip-deleteIcon {
  color: #805537;
}
.MuiAutocompleteCustom .MuiChip-root .MuiChip-deleteIcon:hover {
  opacity: 0.8;
}
.autoOptionList {
  width: 100%;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.autoOptionList .MuiCheckbox-root {
  padding: 4px;
}
.autoOptionList .MuiButtonBase-root {
  min-height: 32px;
}
.MuiAutocomplete-popup .MuiAutocomplete-option {
  border-bottom: 1px solid #f0f0f0;
}
.calendarField button.MuiIconButton-root {
  padding: 2px;
}
.calendarField .MuiIconButton-root .MuiIconButton-label svg path {
  display: none;
}
.calendarField .MuiIconButton-root .MuiIconButton-label svg {
  background: url(../src/assets/calendar_gray.svg) center no-repeat;
}
.MuiPickersDay-daySelected {
  color: #fff !important;
  background-color: #2f72f2 !important;
}
button.MuiPickersDay-current {
  color: #2f72f2;
}
.selectedLabel label {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
  color: #805537;
}
.muiSelectList {
  margin-top: 8px !important;
  margin-bottom: 9px !important;
}
.eyeIcon .MuiIconButton-root {
  padding: 3px;
}
.eyeIcon svg.MuiSvgIcon-root {
  font-size: 1.1rem;
}
.noRecordFound,
.errorFound {
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  position: relative;
  border: 1px solid #f4eddc;
  border-radius: 3px;
  z-index: 1;
}
.noRecordFound img {
  max-width: 100%;
}
.noRecordFound .noRecordText,
.errorFound .noRecordText {
  color: #cacaca;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Roboto', sans-serif, Arial;
  height: 30px;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.errorFound {
  background: url(../src/assets/error-bg.svg) no-repeat center;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  border-radius: 0;
  border: 0;
}
.noImageFound {
  height: 100%;
  display: flex;
  align-items: center;
}
.noImageFound img {
  height: auto !important;
}
.flexView-Heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.dottedInputLine .MuiInput-underline:before {
  border-bottom: 2px dotted rgba(0, 0, 0, 0.2) !important;
}

/* .dottedInputLine label.MuiFormLabel-root,
.dottedInputLine .MuiFormControl-root .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.68);
} */

/* snackbar */
.customSnackbar .MuiSnackbarContent-root {
  min-height: 80px;
  min-width: 600px;
  padding-left: 20px;
  background: #608c1a url(../src/assets/art.svg) left bottom no-repeat;
  position: relative;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.customSnackbar .MuiSnackbarContent-message {
  font-size: 1rem;
  font-weight: 300;
  width: 100%;
}

.customSnackbar .MuiSnackbarContent-action .MuiButton-sizeLarge {
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
  text-decoration: underline;
  padding: 8px 10px;
}

.customSnackbar .snackbarMessage {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}

.customSnackbar .snackbarMessage .messIcon {
  background-color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  margin-right: 10px;
  margin-left: 10%;
}

.customSnackbar .textSection {
  margin-left: 15%;
}

.customTable .MuiTableHead-root .MuiTableCell-root {
  background: #feffed !important;
}

.customTable thead tr th:nth-child(1),
.customTable tbody tr td:nth-child(1) {
  z-index: 300;
  position: sticky !important;
  background-clip: padding-box;
  left: 0;
  min-width: 130px !important;
  max-width: 130px !important;
  width: 130px;
}
.customTable thead tr th:nth-child(2),
.customTable tbody tr td:nth-child(2) {
  z-index: 300;
  position: sticky !important;
  background-clip: padding-box;
  left: 130px;
  min-width: 140px !important;
  max-width: 140px !important;
  width: 140px;
}
.customTable thead tr th:nth-child(3),
.customTable tbody tr td:nth-child(3) {
  z-index: 300;
  position: sticky !important;
  background-clip: padding-box;
  left: 270px;
  min-width: 155px !important;
  max-width: 155px !important;
  width: 155px;
  overflow: visible !important;
}
.customTable tbody tr td:nth-child(3)::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  box-shadow: 3px 0 3px rgba(0, 0, 0, 0.15);
}

/* .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded {
	margin-top: 45px !important;
} */

.pickerIconsCustom button {
  padding: 6px;
}
.pickerIconsCustom img {
  width: 1rem;
}

.notificationHeaderIcon .MuiBadge-badge {
  min-height: 24px !important;
  min-width: 24px !important;
  height: auto;
  border-radius: 50% !important;
  font-size: 11px !important;
  padding: 3px !important;
}

.userBadge {
  font-family: inherit !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}
.subheadersubHeadingText {
  color: #e3faba !important;
}

.MuiTabs-flexContainer .MuiTab-wrapper {
  font-size: 1rem !important;
  color: #7a7a7a;
}
.MuiTabs-root .MuiTabs-flexContainer .MuiTab-wrapper,
.MuiTabs-root .MuiTabs-flexContainer .Mui-selected .MuiTab-wrapper {
  color: #7a7a7a;
  font-weight: 400;
  font-size: 0.925rem !important;
}
.MuiTabs-root .MuiTabs-flexContainer .Mui-selected .MuiTab-wrapper {
  color: #282c34;
}
.MuiTabs-flexContainer .MuiTab-root {
  padding: 13px 20px 0px 20px !important;
}
.Mui-disabled.MuiFormControlLabel-label {
  color: inherit !important;
}
.Mui-disabled .MuiCheckbox-root {
  opacity: 0.4 !important;
}
.MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background-color: #608c1a;
}
.MuiPickersClockPointer-pointer,
.MuiPickersClock-pin {
  background-color: #2f72f2 !important;
}
.MuiPickersClockPointer-pointer .MuiPickersClockPointer-thumb {
  border-color: #2f72f2;
}
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.81) !important;
}

.subHeaderIcon .MuiButton-root {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 0px 3px #354517;
  border: 1px solid #dbcfb8;
  border-radius: 3px;
  opacity: 1;
  width: 36px;
  height: 36px;
  min-width: 36px;
  margin-left: 10px;
  margin-top: 5px;
}

.subHeaderIcon .MuiButton-root:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.bidHeadingText {
  text-align: left;
  /* font-family: "Roboto"; */
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  color: #805537;
  opacity: 1;
}

.whiteBarButton {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  opacity: 1;
  width: 36px !important;
  height: 36px !important;
  min-width: 36px !important;
  box-shadow: 2px 0px 2px #354517;
  border: 1px solid #dbcfb8;
  border-radius: 3px;
  margin-left: 10px !important;
}

.bidNumbersTable .MuiTable-root .MuiTableHead-root .MuiTableCell-root {
  background: #fbfbfb 0% 0% no-repeat padding-box !important;
  /* font-family: "Roboto" !important; */
  font-size: 11px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  color: #805537 !important;
  height: 34px !important;
}

.bidNumbersTable .MuiTable-root tbody .MuiTableCell-root {
  height: 49px !important;
  /* font-family: "Roboto" !important; */
  font-size: 14px !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  color: #484848 !important;
}
.width100Input {
  width: 100%;
  max-width: 200px;
}

.bidNumbersTable {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 3px 3px 10px #71410055 !important;
  border-radius: 3px !important;
  margin-top: 10px !important;
  min-height: calc(100vh - 300px) !important;
}

.statusBtn {
  display: inline-block;
  width: 150px;
  min-height: 20px;
  padding: 5px 0 3px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
}
.color-Approved {
  background-color: #d0f2ca !important;
  color: #349a7b;
}
.color-Closing_Schedule {
  background-color: #caeff2;
  color: #34779a;
}
.color-Disclosed {
  background-color: #ffe7ae;
  color: #b5871b;
}
.color-Returned {
  background-color: #fce1d7;
  color: #9a5734;
}
.color-multiple {
  background-color: #c7c7c4 !important;
  color: #302d2d;
}
.color-Submitted {
  background-color: #f2f2ca;
  color: #9a7734;
}
.color-Deactivated {
  background-color: #f0f0f0;
  color: #707070;
}
.color-Expired {
  background: #fce1d7;
  color: #9a5734;
}
.color-Upcoming {
  background-color: #d0f2ca;
  color: #349a7b;
}
.startedStage {
  margin-right: 10px;
  display: -webkit-inline-box;
  width: 10px;
  height: 10px;
  border: 2px solid #55a362;
  border-radius: 10px;
  opacity: 1;
}
.verifiedStage {
  margin-right: 10px;
  display: -webkit-inline-box;
  width: 10px;
  height: 10px;
  border: 2px solid #fa9c2b;
  border-radius: 10px;
  opacity: 1;
}
.initialStage {
  margin-right: 10px;
  display: -webkit-inline-box;
  width: 10px;
  height: 10px;
  border: 2px solid #9a6cac;
  border-radius: 10px;
  opacity: 1;
}
.taskListPaper {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 3px 3px 10px #71410055 !important;
  border-radius: 3px !important;
  margin-top: 10px !important;
  min-height: calc(100vh - 300px) !important;
}
.customTopBar {
  position: absolute;
  top: 3px;
  right: 41px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
#selectContain .MuiInputBase-root.MuiInput-root.MuiInput-underline {
  margin-top: 3px !important;
  width: 100% !important;
}
#selectContain .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-animated {
  color: #805537 !important;
  font-size: 0.725rem !important;
}
.hiddenElement {
  display: none !important;
}
.paddingClassEventDetails {
  padding: 15px 20px !important;
}
.addLevelText {
  color: #805537 !important;
}
#batch-menu .MuiMenuItem-root {
  min-height: 40px !important;
  min-width: 270px !important;
  font-size: 0.875rem !important;
  border-bottom: 1px solid rgba(199, 200, 200, 0.25) !important;
}
.expansionPanelCustom {
  width: 100% !important;
  flex-basis: calc(100% - 0px) !important;
  max-width: calc(100% - 0px) !important;
  padding: 0 !important;
  min-height: 48px !important;
}
.overflowEllipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.expansionPanelCustom
  .MuiPaper-root.MuiPaper-elevation1.MuiExpansionPanel-root.Mui-expanded.MuiExpansionPanel-rounded.MuiPaper-rounded {
  border: none !important;
  box-shadow: none !important;
}
.expansionPanelCustom .MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0 0 !important;
}
.expansionPanelCustom .MuiExpansionPanelSummary-root {
  min-height: 49px !important;
}
.hiddenInput {
  display: none !important;
}
.checkboxArea {
  display: inline-block;
  min-height: 48px !important;
  vertical-align: top;
}

.profilesCOntainer .contentCardArea {
  width: 95%;
  display: inline-block;
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  border: 1px solid #f4eddc !important;
  border-radius: 3px;
  min-height: 48px !important;
  line-height: 48px !important;
  /* padding: 0 20px; */
}
.profilesCOntainer {
  margin: 44px 0 30px 0;
}

.profilesCOntainer .headText {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Roboto', sans-serif, Arial;
  letter-spacing: 0;
  color: #484848;
  opacity: 1;
}

.profilesCOntainer .Count {
  display: inline-block;
  min-width: 51px;
  text-align: center;
  min-height: 25px;
  border: 1px solid #a2a399;
  border-radius: 100px;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Roboto', sans-serif, Arial;
  letter-spacing: 0;
  color: #805537;
  line-height: 25px;
  margin-left: 15px;
}

.rightContent {
  float: right;
}

.customBadge button {
  background: #a3a3a3 !important;
}

.customBadgeAdd button {
  background: #f4eddc !important;
  border: 1px solid #70707040 !important;
}
.customBadgeAdd img {
  margin: 6px 0 0 1px !important;
}
.textBehindbadge {
  text-align: left;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Roboto', sans-serif, Arial;
  letter-spacing: 0;
  color: #484848;
  opacity: 1;
}

.headerClassContent {
  min-height: 48px !important;
  line-height: 48px !important;
  min-width: 100%;
  background: #ffffff;
  padding: 0 20px;
}

.mainCardContent {
  background: #f7f7f7;
  padding: 20px 10px;
  max-height: 520px !important;
  min-width: 100% !important;
  overflow-y: auto !important;
}

.customCard {
  position: relative;
  background: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border: 1px solid #70707038 !important;
  border-radius: 3px !important;
  margin: 10px 0 !important;
}
.cardIconCont {
  width: 15%;
  display: inline-block;
  vertical-align: top;
}
.cardTextCont {
  width: 85%;
  display: inline-block;
  vertical-align: top;
}
.cardTextCont .headTextContent {
  text-align: left !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif, Arial !important;
  letter-spacing: 0 !important;
  color: #333333 !important;
  margin: 5px 0;
  max-height: 14px;
  line-height: 14px;
}
.cardTextCont .headNameTextContent {
  text-align: left !important;
  font-weight: 200 !important;
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif, Arial !important;
  letter-spacing: 0 !important;
  color: #333333 !important;
  margin: 0;
  max-height: 16px;
  line-height: 16px;
}
.cardInputCont {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 20px 20px;
}

.p10All {
  padding: 10px;
}

.posAbsCloseButton {
  position: absolute;
  right: 0px;
  top: 0px;
}

.textSectionProgress {
  margin-left: 10px;
  min-width: 33%;
  max-width: 33%;
  display: inline-block;
  position: relative;
}
.leftTextSectionProgress {
  display: inline-block;
  width: 50%;
  text-align: left;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif, Arial !important;
  letter-spacing: 0;
  color: #484848;
}
.righTtextSectionProgress {
  display: inline-block;
  width: 50%;
  text-align: right;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif, Arial !important;
  letter-spacing: 0;
  color: #484848;
}
.biggerTextProgress {
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif, Arial !important;
  letter-spacing: 0;
  color: #484848;
}
.backProgressColor {
  background: #dedede;
  border-radius: 100px;
  min-width: 100%;
  max-width: 100%;
  min-height: 5px;
  overflow: hidden;
}

.frontProgressColor {
  background: #36c168 !important;
  border-radius: 100px;
  min-height: 5px;
}

.errorTextProgress {
  margin-left: 10px;
  color: crimson;
  font-weight: 500 !important;
  font-size: 12px !important;
  font-family: 'Roboto', sans-serif, Arial !important;
}
.tabDataText {
  margin: 0px !important;
}
.classBoldText {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif, Arial !important;
}
.marRightGrip {
  margin-right: 16px !important;
}
.posAbsRightBtn {
  position: absolute !important;
  right: 10px !important;
}
.selectionZone {
  width: 100%;
  min-height: 27px;
}

.noTitleDialogNew .MuiDialogTitle-root {
  background: rgb(85, 114, 29);
  background: linear-gradient(
    0deg,
    rgba(85, 114, 29, 1) 0%,
    rgba(95, 138, 26, 1) 100%
  );
  padding: 0;
  overflow: hidden;
  position: relative;
}
.noTitleDialogNew .MuiDialogTitle-root,
.noTitleDialogNew .MuiDialogTitle-root .popupBgPic {
  height: 112px;
  text-align: center;
}

.imageDialog {
  background-size: 100%;
  background-position: 0;
}

.imageDialog
  .MuiPaper-root.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded {
  background: transparent !important;
  overflow: hidden !important;
}

.imageDialog .imageSize {
  max-height: 90%;
  max-width: 100%;
  background: transparent;
  overflow: hidden;
}

.imageDialog .closeBtn {
  background: transparent;
  overflow: hidden;
  position: fixed;
  right: 8px;
  top: 8px;
  color: #fff;
  padding: 5px;
}
.imageDialog .closeBtn .MuiSvgIcon {
  font-size: 1.4rem;
}

.noTitleDialog .MuiDialogTitle-root .popupBgPic img {
  max-width: 100%;
}
.noTitleDialog .headingTitle {
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.simpleDialog .MuiDialog-paper {
  max-width: 100%;
}
.smallText {
  font-size: 0.875rem !important;
  line-height: 1.2 !important;
}
.wd500 {
  width: 500px;
}
.errorClassSnack .MuiSnackbarContent-root {
  background: #608c1a url(../src/assets/art.svg) left bottom no-repeat !important;
  z-index: 99999999;
}
.customSnackbar .messIcon img.snackErrorIcon {
  display: none;
}
.errorClassSnack .messIcon img.snackSuccessIcon {
  display: none;
}
.errorClassSnack .messIcon img.snackErrorIcon {
  display: inline-block;
}
.redAlertText {
  color: #f44336;
  font-size: 0.75rem;
}
.textCircle {
  position: absolute;
  left: 4px;
  top: 3px;
  min-width: 26px;
  min-height: 26px;
  max-width: 26px;
  max-height: 26px;
  border-radius: 50%;
  overflow: hidden;
  line-height: 26px;
  pointer-events: auto;
  background: #fce1d7 0% 0% no-repeat padding-box;
  box-shadow: none;
}

.react-calendar__tile {
  position: relative;
  padding: 0;
  border-radius: 0 !important;
  pointer-events: none;
}
.react-calendar__tile--active {
  background-color: transparent !important;
  color: black !important;
}
.notificationCustom .MuiMenu-paper {
  width: 280px !important;
  max-width: 280px !important;
}
.notificationCustom .MuiMenuItem-root {
  width: 391px;
  max-height: 366px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 10px #00000029;
  opacity: 1;
  overflow: scroll;
}
.notificationHeader {
  min-width: 100%;
  display: inline-flex;
}
.notificationText {
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  color: #9a5734;
  opacity: 1;
  flex-grow: 1;
  font-family: 'Roboto', sans-serif, Arial;
}
.notificationClear {
  flex-grow: 1;
  text-align: right;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  color: #3b86ff;
  opacity: 1;
  font-family: 'Roboto', sans-serif, Arial;
}
.notificationLayout {
  width: 391px;
  height: 74px;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
}
.alertLogo {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  background: #ff5242 0% 0% no-repeat padding-box;
  opacity: 0.2;
  padding: 3px;
}
.bellIcon {
  width: 12px;
  height: 12px;
  opacity: 1;
  padding: 2px;
}
.notificationContent {
  min-width: calc(100% - 60px);
  display: inline-block;
  word-wrap: break-word;
  max-width: 100%;
  white-space: normal;
  letter-spacing: 0;
  color: #323338;
  font-size: 13px;
}
.notificationIcon {
  min-width: 40px;
  display: inline-block;
}
.notificationIconClose {
  min-width: 20px;
  display: inline-block;
}
.notificationCloseIcon {
  width: 10px;
  height: 10px;
}
.notificationBody {
  width: 391px;
  max-height: 366px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 10px #00000029;
  opacity: 1;
  overflow-x: hidden;
}
.noNewNotificationText {
  text-align: center;
  min-width: 100%;
}
.overdueCustom {
  color: red;
}
.reminderCustom {
  color: #2f72f2;
}
.noUnderLineFields .MuiInput-underline::before {
  border: 0px !important;
}
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none !important;
}
.disabled-Tabs {
  opacity: 0.66;
  pointer-events: none;
}
.react-calendar__year-view__months__month .textCircle,
.react-calendar__decade-view__years__year .textCircle {
  display: none !important;
}

.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year {
  pointer-events: auto !important;
}
.react-calendar__tile--active .textCircle {
  background-color: #2f72f2 !important;
  color: #ffffff;
}
.chipsClassCustom {
  display: flex !important;
  flex-wrap: wrap;
}
.chipClassCustom {
  margin: 4px !important;
}

.pageError {
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
}

.pageError img {
  margin: auto;
}

.pageError .noRecordText {
  text-transform: none;
  font-size: 1.3rem;
  font-weight: 400;
  color: #b3b3b3;
}

.pageError .noRecordText .errorBigText {
  font-size: 3rem;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}
