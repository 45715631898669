@import "loaders.css/src/animations/ball-pulse.scss";
@import "fonts/fonts.css";

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

body {
  background: #feffed url(../src/assets/dashboard_bg.png) 0% 0% no-repeat padding-box;
  background-size: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif, Arial;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.container-fluid {
  padding: 0 25px;
  margin: 0 auto;
  width: 100%;
  display: block;
}
.container {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}
.backgroundOpaceLoader {
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1999;
  min-width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
}
.backgroundOpaceLoader2 {
  background-color: rgb(245, 245, 245);
  z-index: 1999;
  min-width: 100%;
  position: static;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  height: 282px;
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  // background-color: rgba(0, 0, 0, 0.01);
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.1);
}
.actionButtonsContainer {
  position: relative;
}
.catpitalizeText {
  text-transform: capitalize;
}

#table td,
#table td * {
  text-transform: capitalize;
}
