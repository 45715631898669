@font-face { font-family: 'Roboto'; font-style: normal; font-weight: 100; src: url('roboto-v20-latin-100.eot'); src: local('Roboto Thin'), local('Roboto-Thin'), url('roboto-v20-latin-100.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-100.woff2') format('woff2'), url('roboto-v20-latin-100.woff') format('woff'), url('roboto-v20-latin-100.ttf') format('truetype'), url('roboto-v20-latin-100.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: italic; font-weight: 100; src: url('roboto-v20-latin-100italic.eot'); src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'), url('roboto-v20-latin-100italic.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-100italic.woff2') format('woff2'), url('roboto-v20-latin-100italic.woff') format('woff'), url('roboto-v20-latin-100italic.ttf') format('truetype'), url('roboto-v20-latin-100italic.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: normal; font-weight: 300; src: url('roboto-v20-latin-300.eot'); src: local('Roboto Light'), local('Roboto-Light'), url('roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-300.woff2') format('woff2'), url('roboto-v20-latin-300.woff') format('woff'), url('roboto-v20-latin-300.ttf') format('truetype'), url('roboto-v20-latin-300.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: italic; font-weight: 300; src: url('roboto-v20-latin-300italic.eot'); src: local('Roboto Light Italic'), local('Roboto-LightItalic'), url('roboto-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-300italic.woff2') format('woff2'), url('roboto-v20-latin-300italic.woff') format('woff'), url('roboto-v20-latin-300italic.ttf') format('truetype'), url('roboto-v20-latin-300italic.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: normal; font-weight: 400; src: url('roboto-v20-latin-regular.eot'); src: local('Roboto'), local('Roboto-Regular'), url('roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-regular.woff2') format('woff2'), url('roboto-v20-latin-regular.woff') format('woff'), url('roboto-v20-latin-regular.ttf') format('truetype'), url('roboto-v20-latin-regular.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: italic; font-weight: 400; src: url('roboto-v20-latin-italic.eot'); src: local('Roboto Italic'), local('Roboto-Italic'), url('roboto-v20-latin-italic.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-italic.woff2') format('woff2'), url('roboto-v20-latin-italic.woff') format('woff'), url('roboto-v20-latin-italic.ttf') format('truetype'), url('roboto-v20-latin-italic.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: normal; font-weight: 500; src: url('roboto-v20-latin-500.eot'); src: local('Roboto Medium'), local('Roboto-Medium'), url('roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-500.woff2') format('woff2'), url('roboto-v20-latin-500.woff') format('woff'), url('roboto-v20-latin-500.ttf') format('truetype'), url('roboto-v20-latin-500.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: italic; font-weight: 500; src: url('roboto-v20-latin-500italic.eot'); src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'), url('roboto-v20-latin-500italic.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-500italic.woff2') format('woff2'), url('roboto-v20-latin-500italic.woff') format('woff'), url('roboto-v20-latin-500italic.ttf') format('truetype'), url('roboto-v20-latin-500italic.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: normal; font-weight: 700; src: url('roboto-v20-latin-700.eot'); src: local('Roboto Bold'), local('Roboto-Bold'), url('roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-700.woff2') format('woff2'), url('roboto-v20-latin-700.woff') format('woff'), url('roboto-v20-latin-700.ttf') format('truetype'), url('roboto-v20-latin-700.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: italic; font-weight: 700; src: url('roboto-v20-latin-700italic.eot'); src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'), url('roboto-v20-latin-700italic.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-700italic.woff2') format('woff2'), url('roboto-v20-latin-700italic.woff') format('woff'), url('roboto-v20-latin-700italic.ttf') format('truetype'), url('roboto-v20-latin-700italic.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: normal; font-weight: 900; src: url('roboto-v20-latin-900.eot'); src: local('Roboto Black'), local('Roboto-Black'), url('roboto-v20-latin-900.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-900.woff2') format('woff2'), url('roboto-v20-latin-900.woff') format('woff'), url('roboto-v20-latin-900.ttf') format('truetype'), url('roboto-v20-latin-900.svg#Roboto') format('svg'); }
@font-face { font-family: 'Roboto'; font-style: italic; font-weight: 900; src: url('roboto-v20-latin-900italic.eot'); src: local('Roboto Black Italic'), local('Roboto-BlackItalic'), url('roboto-v20-latin-900italic.eot?#iefix') format('embedded-opentype'), url('roboto-v20-latin-900italic.woff2') format('woff2'), url('roboto-v20-latin-900italic.woff') format('woff'), url('roboto-v20-latin-900italic.ttf') format('truetype'), url('roboto-v20-latin-900italic.svg#Roboto') format('svg'); }